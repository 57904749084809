<template>
  <div>
    <div
      v-if="mode === 'chat'"
      class="p-2 d-flex align-items-center user-card"
      :class="{ 'user-card-active': active }"
      @click="$emit('click')"
    >
      <mega-image
        style="overflow: hidden"
        class="mr-2 rounded-full user-avatar"
        ratio="1x1"
        :src="avatar"
      />
      <div
        class="user-identity d-flex flex-column justify-content-center"
        style="align-items: start"
      >
        <small v-if="name.length > 0" class="strong text-overflow mr-1">
          {{ name }}
        </small>
        <small v-else class="text-muted text-overflow">
          noname
        </small>

        <small class="text-muted text-overflow"> @{{ userId }} </small>
      </div>
      <div
        class="user-unread-messages d-flex justify-content-center align-items-center"
        v-if="unreadMessages"
      >
        {{ unreadMessages }}
      </div>
    </div>

    <div
      v-else
      class="p-2 d-flex align-items-start user-card"
      :class="{ 'user-card-active': active }"
      @click="$emit('click')"
    >
      <mega-image
        style="overflow: hidden"
        class="mr-2 rounded-full user-avatar"
        ratio="1x1"
        :src="avatar"
      />
      <div class="d-flex flex-column">
        <div
          class="user-identity d-flex flex-row justify-content-start"
          style="align-items: start"
        >
          <small v-if="name.length > 0" class="strong text-overflow mr-1">
            {{ name }}
          </small>
          <small v-else class="text-muted text-overflow">
            noname
          </small>

          <small class="text-muted text-overflow"> @{{ userId }} </small>
        </div>
        <div class="last-message d-flex flex-row">
          <span class="text-muted mr-2" v-if="lastMessage[1] == 1"
            >{{ $t("you") }}:
          </span>
          <span class="text-muted mr-2" v-else>
            {{ name.split(" ")[0] }}:
          </span>
          <span>
            {{ lastMessage[0] }}
          </span>
        </div>
      </div>
      <div
        class="align-self-center user-unread-messages d-flex justify-content-center align-items-center"
        v-if="unreadMessages"
      >
        {{ unreadMessages }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 65px;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 50px minmax(100px, 100%) auto;
}
.user-unread-messages {
  font-size: 0.7rem;
  background-color: #0078dc;
  border-radius: 50px;
  height: 20px;
  min-width: 20px;
  line-height: 1;
  color: #fff;
  padding: 3px;
}
.user-card:last-of-type {
  border: 0;
}
.user-avatar {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
}
.user-card-active {
  background-color: #0078dc;
}
.user-card-active small {
  color: #fff;
}
</style>

<script>
export default {
  name: "User",
  props: {
    active: { type: Boolean, required: false, default: false },
    avatar: { type: String, required: false, default: "" },
    name: { type: String, required: true },
    userId: { type: String, required: true },
    unreadMessages: { default: false },
    lastMessage: [Array, String],
    mode: [String]
  }
};
</script>
