var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.mode === "chat"
      ? _c(
          "div",
          {
            staticClass: "p-2 d-flex align-items-center user-card",
            class: { "user-card-active": _vm.active },
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          },
          [
            _c("mega-image", {
              staticClass: "mr-2 rounded-full user-avatar",
              staticStyle: { overflow: "hidden" },
              attrs: { ratio: "1x1", src: _vm.avatar }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "user-identity d-flex flex-column justify-content-center",
                staticStyle: { "align-items": "start" }
              },
              [
                _vm.name.length > 0
                  ? _c("small", { staticClass: "strong text-overflow mr-1" }, [
                      _vm._v("\n        " + _vm._s(_vm.name) + "\n      ")
                    ])
                  : _c("small", { staticClass: "text-muted text-overflow" }, [
                      _vm._v("\n        noname\n      ")
                    ]),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted text-overflow" }, [
                  _vm._v(" @" + _vm._s(_vm.userId) + " ")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.unreadMessages
              ? _c(
                  "div",
                  {
                    staticClass:
                      "user-unread-messages d-flex justify-content-center align-items-center"
                  },
                  [_vm._v("\n      " + _vm._s(_vm.unreadMessages) + "\n    ")]
                )
              : _vm._e()
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "p-2 d-flex align-items-start user-card",
            class: { "user-card-active": _vm.active },
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          },
          [
            _c("mega-image", {
              staticClass: "mr-2 rounded-full user-avatar",
              staticStyle: { overflow: "hidden" },
              attrs: { ratio: "1x1", src: _vm.avatar }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                {
                  staticClass:
                    "user-identity d-flex flex-row justify-content-start",
                  staticStyle: { "align-items": "start" }
                },
                [
                  _vm.name.length > 0
                    ? _c(
                        "small",
                        { staticClass: "strong text-overflow mr-1" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.name) + "\n        "
                          )
                        ]
                      )
                    : _c("small", { staticClass: "text-muted text-overflow" }, [
                        _vm._v("\n          noname\n        ")
                      ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted text-overflow" }, [
                    _vm._v(" @" + _vm._s(_vm.userId) + " ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "last-message d-flex flex-row" }, [
                _vm.lastMessage[1] == 1
                  ? _c("span", { staticClass: "text-muted mr-2" }, [
                      _vm._v(_vm._s(_vm.$t("you")) + ":\n        ")
                    ])
                  : _c("span", { staticClass: "text-muted mr-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.name.split(" ")[0]) +
                          ":\n        "
                      )
                    ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.lastMessage[0]) + "\n        "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.unreadMessages
              ? _c(
                  "div",
                  {
                    staticClass:
                      "align-self-center user-unread-messages d-flex justify-content-center align-items-center"
                  },
                  [_vm._v("\n      " + _vm._s(_vm.unreadMessages) + "\n    ")]
                )
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }